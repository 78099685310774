import ExplorePage from "src/components/explore/ExplorePage";
import {
  CONTENTFUL_FOOTER_MENU_ID,
  CONTENTFUL_FOOTER_SALES_POINTS_ID,
  convertContentfulSEOMetadataToSEOProps,
  fallbackOgImageMetaProvider,
  getExplorePage,
  getFooterCopyResourceSet,
  getMenu,
  getSalesPoints,
} from "src/lib/contentful";
import { getFooterProps } from "src/lib/footer";
import { getPromoBannerProps } from "src/lib/promoBanner";
import { getCollectionPages, getFacets } from "src/lib/search";
import { getPromoBannerData } from "src/lib/seekr";
import wrapper from "src/state/createStore";

export default ExplorePage;

export const getStaticProps = wrapper.getStaticProps(
  async ({ preview = false }) => {
    const explorePageData = await getExplorePage("home/", preview);
    const collectionPages = await getCollectionPages(explorePageData);
    const childPages = await getFacets();

    const fallbackOgImageURL =
      explorePageData?.heroImage?.url || explorePageData?.cardImage?.url;

    const seoMetadata = convertContentfulSEOMetadataToSEOProps(
      explorePageData?.seoMetadata,
      (contentfulOgImage) =>
        fallbackOgImageMetaProvider(contentfulOgImage, {
          url: fallbackOgImageURL,
          width: 1200,
          height: 630,
        })
    );

    const footerPropsPromise = getFooterProps(
      () => getMenu(CONTENTFUL_FOOTER_MENU_ID, preview),
      () => getSalesPoints(CONTENTFUL_FOOTER_SALES_POINTS_ID, preview),
      () => getFooterCopyResourceSet(preview)
    );

    const promoBannerPropsPromise = getPromoBannerProps(() =>
      getPromoBannerData("/")
    );

    const [footerProps, promoBannerProps] = await Promise.all([
      footerPropsPromise,
      promoBannerPropsPromise,
    ]);

    return {
      props: {
        currentPath: [
          {
            value: "Our Adventures",
          },
        ],
        explorePageData,
        childPages,
        collectionPages,
        seoMetadata,
        footer: footerProps,
        promoBanner: promoBannerProps,
      },
      revalidate: 60, //seconds
    };
  }
);
